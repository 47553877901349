import React, { ReactChild } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { graphql, useStaticQuery } from 'gatsby';
import Header from '../Header';
import Footer from '../Footer';
import SEO from '../Seo';
import { SiteSettings } from './types';

type LayoutProps = {
  children: ReactChild;
  pageName: string;
  description: string;
  isHome: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, pageName, description, isHome }) => {
  const { allContentfulSiteSettings: siteSettingsData } = useStaticQuery(
    graphql`
      query {
        allContentfulSiteSettings {
          nodes {
            siteName
            siteAuthor
            phone
            email
            copyright
            footerNavigation {
              url
              label
              internalLink {
                slug
                title
              }
            }
          }
        }
      }
    `
  );

  const siteSettings: SiteSettings = {
    siteName: siteSettingsData.nodes[0].siteName,
    siteAuthor: siteSettingsData.nodes[0].siteAuthor,
    email: siteSettingsData.nodes[0].email,
    phone: siteSettingsData.nodes[0].phone,
    copyright: siteSettingsData.nodes[0].copyright,
    ogImage: `${process.env.DOMAIN}/ogimage.jpg`,
    socialLinks: [
      {
        label: 'facebook',
        url: 'https://www.facebook.com/test',
      },
      {
        label: 'instagram',
        url: 'https://www.instagram.com/test/',
      },
    ],
    footerNav1: siteSettingsData.nodes[0].footerNavigation,
  };

  return (
    <ParallaxProvider>
      <SEO
        title={`${siteSettings.siteName} - ${pageName}`}
        description={description}
        author={siteSettings.siteAuthor}
      />
      <Header siteSettings={siteSettings} isHome={isHome} />
      <main>{children}</main>
      <Footer siteSettings={siteSettings} />
    </ParallaxProvider>
  );
};

export default Layout;
