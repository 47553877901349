import React from 'react';
import close from './icons/close.svg';
import phone from './icons/phone.svg';
import mail from './icons/mail.svg';
import pin from './icons/pin.svg';
import arrow from './icons/arrow.svg';
import logo from './icons/logo.svg';
import logoSmall from './icons/logo_small.svg';

export type IconType = 'close' | 'logo' | 'logoSmall' | 'phone' | 'mail' | 'pin' | 'arrow';

type IconProps = {
  name: IconType;
  width?: number;
  height?: number;
  additionalClass?: string;
};

const Icon: React.FC<IconProps> = ({ name, width, height, additionalClass }) => {
  if (!name) {
    return null;
  }

  const icons = {
    close,
    logo,
    logoSmall,
    phone,
    mail,
    pin,
    arrow,
  };

  const CurrentIcon = icons[name];

  if (!CurrentIcon) return null;

  return <CurrentIcon width={width} height={height} className={additionalClass} />;
};

export default Icon;
