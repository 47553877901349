import React from 'react';
import PageHeader from './PageHeader';
import Text from './Text';
import FullwidthImageTeaser from './FullwidthImageTeaser';
import ImageGallery from './ImageGallery';
import AccordionTeaser from './AccordionTeaser';
import GoogleMapsTeaser from './GoogleMapsTeaser';
import ImageSlider from './ImageSlider';
import BookingForm from './BookingForm';
import KPI from './KPI';

const ContentComonents = ({ components }) => {
  const allowedContentItems = {
    ContentfulComponentPageHeader: PageHeader,
    ContentfulComponentFullwidthImageTeaser: FullwidthImageTeaser,
    ContentfulComponentText: Text,
    ContentfulComponentImageGallery: ImageGallery,
    ContentfulComponentImageSlider: ImageSlider,
    ContentfulComponentAccordionTeaser: AccordionTeaser,
    ContentfulComponentGoogleMapsTeaser: GoogleMapsTeaser,
    ContentfulComponentBooking: BookingForm,
    ContentfulComponentKpi: KPI,
  };

  return components.map(contentItem => {
    if (!contentItem.internal) return false;
    if (!(contentItem.internal.type in allowedContentItems)) return false;
    const ContentItem = allowedContentItems[contentItem.internal.type];
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ContentItem key={contentItem.id} {...contentItem} />;
  });
};

export default ContentComonents;
