import React from 'react';
import c from 'classnames';
import { Link } from 'gatsby';
import Icon from '../../components/ui/Icon';
import { SiteSettings } from '../Layout/types';
import * as styles from './styles.module.scss';

type NavigationProps = {
  siteSettings: SiteSettings;
  isHome: boolean;
};

const MainNav: React.FC<NavigationProps> = ({ siteSettings, isHome }) => {
  return (
    <nav className={c(styles.navigation, { [styles.isHome]: isHome })}>
      <div className={styles.wrapper}>
        <Link to="/">
          <Icon name="logo" additionalClass={styles.logo} />
        </Link>
        <div className={styles.iconWrapper}>
          <a href={`tel:${siteSettings.phone}`} className={styles.icon} target="_blank" rel="noreferrer">
            <Icon name="phone" height={18} additionalClass="self-center mr-30" />
          </a>
          <a href={`mailto:${siteSettings.email}`} className={styles.icon} target="_blank" rel="noreferrer">
            <Icon name="mail" height={18} additionalClass="self-center" />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default MainNav;
