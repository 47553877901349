import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import Headline from '../../ui/Headline';
import Image from '../../ui/Image';
import { IImage } from '../../ui/Image/types';
import * as styles from './styles.module.scss';

type PageHeaderProps = {
  media: IImage;
  title: string;
  shortText: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ image, title, shortText }) => {
  return (
    <section className={styles.container}>
      <Image image={image.image} additionalClass={styles.img} />
      <div className={styles.wrapper}>
        <Parallax y={[-20, 20]}>
          {shortText !== null && <p className={styles.text}>{shortText}</p>}
          {title !== null && (
            <Headline level={1} color="White" additionalClass={styles.hl}>
              {title}
            </Headline>
          )}
        </Parallax>
      </div>
    </section>
  );
};

export default PageHeader;
