import React from 'react';
import Container from '../../ui/Grid/Container';
import * as styles from './styles.module.scss';
import { IImage } from '../../ui/Image/types';
import Image from '../../ui/Image';

type ImageGalleryProps = {
  images: Array<IImage>;
};

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  return (
    <Container additionalClass={styles.container} fullWidth>
      <div className={styles.flexContainer}>
        {images.map((image, idx) => (
          <Image image={image.image} additionalClass={styles.img} key={idx} />
        ))}
      </div>
    </Container>
  );
};

export default ImageGallery;
