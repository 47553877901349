/* eslint-disable react/display-name */
import cn from 'classnames';
import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import Arrow from './components/Arrow';
import * as styles from './style.module.scss';

type ContentSliderProps = {
  children: React.ReactNode;
  additionalSettings?: Settings;
  additionalClass?: string;
  ref?: string;
};

const defaultSettings = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};

const ContentSlider: React.FC<ContentSliderProps> = React.forwardRef((props, ref) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { children, additionalClass, additionalSettings } = props;
  const Dots = (dots: React.ReactNodeArray) => (
    <div>
      <ul className={cn(styles.dots)}>{dots}</ul>
    </div>
  );

  const settings = {
    ...defaultSettings,
    ...additionalSettings,
    appendDots: Dots,
    prevArrow: activeSlide > 0 ? <Arrow direction="left" /> : <div />,
    nextArrow: activeSlide < children.length - 1 ? <Arrow direction="right" /> : <div />,
    afterChange: current => setActiveSlide(current),
  };

  return (
    <Slider ref={ref} className={cn('pb-45', additionalClass)} {...settings}>
      {children}
    </Slider>
  );
});

export default ContentSlider;
