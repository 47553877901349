import { Link } from 'gatsby';
import React, { ReactNode } from 'react';

type FlexibleLinkProps = {
  link: {
    label: string;
    url: string;
    internalLink: {
      slug: string;
      title: string;
    };
  };
  children: ReactNode;
  additionalClass?: string;
};

const FlexibleLinkWrapper: React.FC<FlexibleLinkProps> = ({ link, children, additionalClass }) => {
  if (link.url) {
    return (
      <a href={link.url || ''} className={additionalClass}>
        {children}
      </a>
    );
  }

  return (
    <Link to={link.internalLink.slug} className={additionalClass}>
      {children}
    </Link>
  );
};

export default FlexibleLinkWrapper;
