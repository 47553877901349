import React, { useState } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import RichText from '../RichText';
import * as styles from './style.module.scss';

export type AccordionProps = {
  accordionEntries: Array<AccordionEntry>;
  accordionType: 'accordion' | 'list';
  changeIndex?: string;
};

export type AccordionEntry = {
  headline: string;
  text: Document;
};

const Accordion: React.FC<AccordionProps> = ({ accordionEntries, accordionType, changeIndex }) => {
  const [activeItemId, setActiveItemId] = useState(0);

  const toggle = (index: number) => {
    if (activeItemId === index) return setActiveItemId(null);
    changeIndex(index);
    return setActiveItemId(index);
  };

  return (
    <div className={cn(styles.container, { [styles.isAccordion]: accordionType === 'accordion' })}>
      {accordionEntries.map((accordionEntry, index) => (
        <div className={cn(styles.item, { [styles.isActive]: activeItemId === index })} key={index}>
          <div
            onClick={accordionType === 'accordion' ? () => toggle(index) : () => {}}
            role="button"
            tabIndex={0}
            onKeyDown={e => e.code === '13' && toggle(index)}
            className="flex flex-row content-center"
          >
            <p className={styles.entryHeadline}>{accordionEntry.headline}</p>
            <Icon name="arrow" width={10} additionalClass={cn(styles.icon)} />
            {activeItemId === index && (
              <Icon
                name="close"
                width={15}
                additionalClass={cn(styles.icon, activeItemId === index && styles.iconActive)}
              />
            )}
          </div>
          <div className={styles.text}>
            <RichText content={accordionEntry.text} additionalClass="ml-20 pt-10" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
