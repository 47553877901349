import React from 'react';
import Col from '../../components/ui/Grid/Col';
import Container from '../../components/ui/Grid/Container';
import Row from '../../components/ui/Grid/Row';
import Icon from '../../components/ui/Icon';
import { SiteSettings } from '../Layout/types';
import FooterNavigation from '../FooterNavigation';
import FlexibleLinkWrapper from '../../components/ui/FlexibleLinkWrapper';
import * as styles from './styles.module.scss';

type FooterProps = {
  siteSettings: SiteSettings;
};

const Footer: React.FC<FooterProps> = ({ siteSettings }) => {
  return (
    <footer>
      <Container additionalClass={styles.container}>
        <Row>
          <Col desktop={6} mobile={6}>
            <FlexibleLinkWrapper link={{ label: '', url: '', internalLink: { slug: '/', title: '' } }}>
              <Icon name="logoSmall" height={65} />
            </FlexibleLinkWrapper>
          </Col>
          <Col desktop={6} mobile={6} additionalClass="self-center">
            <FooterNavigation footerNavigation={siteSettings.footerNav1} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
