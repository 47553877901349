import React from 'react';
import cn from 'classnames';
import Headline from '../../ui/Headline';
import Container from '../../ui/Grid/Container';
import RichText from '../../ui/RichText';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import * as styles from './styles.module.scss';
import FlexibleLink from '../../ui/FlexibleLink';
import { IImage } from '../../ui/Image/types';
import { ILink } from '../../ui/FlexibleLink/type';
import Image from '../../ui/Image';

type TextProps = {
  headline: string;
  text: Document;
  image?: IImage;
  link?: ILink;
  textCenter: Boolean;
  noSpace: Boolean;
};

const Text: React.FC<TextProps> = ({ headline, text, link, image, textCenter, noSpace }) => {
  return (
    <Container additionalClass={cn(styles.container, { [styles.noSpace]: noSpace })}>
      <Row>
        <Col mobile={12} tablet={8} desktop={8} centered additionalClass={cn({ 'text-center': textCenter })}>
          {image && <Image image={image.image} additionalClass={styles.img} />}
          {headline && (
            <Headline level={2} classLevel={3}>
              {headline}
            </Headline>
          )}
          {text && (
            <div className={cn(styles.textWrapper, { 'mb-50': link })}>
              <RichText content={text} />
            </div>
          )}
          {link && <FlexibleLink link={link} />}
        </Col>
      </Row>
    </Container>
  );
};

export default Text;
