import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import c from 'classnames';
import Headline from '../Headline';
import FlexibleLink from '../FlexibleLink';
import * as styles from './style.module.scss';

type RichTextProps = {
  content: Document;
  additionalClass?: string;
};

const useContentfulImage = (assetUrl: any) => {
  const { assets } = useStaticQuery(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        assets: allContentfulAsset {
          edges {
            node {
              contentful_id
              fluid(maxWidth: 500, quality: 85) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  );
  const asset = assets.edges.find(({ node }) => node.contentful_id === assetUrl);
  return asset;
};

const RichText: React.FC<RichTextProps> = ({ content, additionalClass = undefined }) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: function renderCopyText(_node: any, children: ReactNode) {
        return <p className={styles.p}>{children}</p>;
      },
      [BLOCKS.HEADING_1]: function renderHeading1(_node: any, children: ReactNode) {
        return (
          <Headline level={1} additionalClass="mb-20">
            {children}
          </Headline>
        );
      },
      [BLOCKS.HEADING_2]: function renderHeading2(_node: any, children: ReactNode) {
        return (
          <Headline level={2} additionalClass="my-20">
            {children}
          </Headline>
        );
      },
      [BLOCKS.HEADING_3]: function renderHeading3(_node: any, children: ReactNode) {
        return (
          <Headline level={3} additionalClass="my-20">
            {children}
          </Headline>
        );
      },
      [BLOCKS.HR]: function renderEmbeddedHR() {
        return <hr />;
      },
      [BLOCKS.OL_LIST]: function renderEmbeddedUL(node: any, children: ReactNode) {
        return <ol className={styles.ol}>{children}</ol>;
      },
      [BLOCKS.UL_LIST]: function renderEmbeddedUL(node: any, children: ReactNode) {
        return <ul className={styles.ul}>{children}</ul>;
      },
      [BLOCKS.LIST_ITEM]: function renderEmbeddedLI(node: any, children: ReactNode) {
        return <li>{children}</li>;
      },
      [INLINES.HYPERLINK]: function renderHyperlink(node: any) {
        return (
          <FlexibleLink link={{ label: node.content[0].value, url: node.data.uri }} additionalClass={styles.link} />
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: function renderEmbeddedImage(node) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const asset = useContentfulImage(node.data?.target?.sys.id);
        if (!asset) return null;
        return <img src={asset.node.fluid.src} alt="" />;
      },
    },
    renderText: (text: string) => {
      return text.split('\n').reduce((children: Array<ReactNode>, textSegment: string, index: number) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return <div className={c(styles.text, additionalClass)}>{renderRichText(content, options)}</div>;
};

export default RichText;
