import React from 'react';
import Container from '../../ui/Grid/Container';
import * as styles from './styles.module.scss';

type BookingFormProps = {};

const BookingForm: React.FC<BookingFormProps> = () => {
  return (
    <Container additionalClass={styles.container}>
      <iframe title="booking" src="/booking.html" loading="lazy" width="100%" height="2900px" />
    </Container>
  );
};

export default BookingForm;
