import React from 'react';
import Col from '../../ui/Grid/Col';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import * as styles from './styles.module.scss';

const KPI = ({ items }) => {
  return (
    <Container additionalClass={styles.container}>
      <Row>
        <Col mobile={12} desktop={12} additionalClass="desktop:flex desktop:px-100 justify-around">
          {items.map((item, i) => (
            <div className={styles.kpiItem} key={i}>
              <span className={styles.prefix}>{item.prefix}</span>
              <span className={styles.value}>{item.value}</span>
              <span>{item.suffix}</span>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default KPI;
