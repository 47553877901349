import React from 'react';
import c from 'classnames';

type ContainerProps = {
  additionalClass?: string;
  fullWidth?: boolean;
};

const Container: React.FC<ContainerProps> = ({ children, additionalClass, fullWidth }) => {
  return (
    <section className={c('mx-auto w-full', { 'max-w-screen-xxl px-15': !fullWidth }, additionalClass)}>
      <div className={c('w-full', { 'max-w-screen-xxl px-15 mx-auto': fullWidth })}>{children}</div>
    </section>
  );
};

export default Container;
