import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IImage } from './types';

type ImageProps = {
  image: IImage;
  focalPoint?: 'top' | 'center' | 'bottom';
  title?: string;
  additionalClass?: string;
};

const Image: React.FC<ImageProps> = ({ image, focalPoint = 'center', additionalClass }) => {
  if (image.gatsbyImageData) {
    return (
      <GatsbyImage
        image={image?.gatsbyImageData}
        alt={image?.description}
        objectPosition={focalPoint}
        className={additionalClass}
      />
    );
  }
  if (image?.file?.url) {
    return <img src={`https:${image.file.url}`} alt={image.description} className={additionalClass} />;
  }
  if (image?.file?.contentType.indexOf('svg') >= 0) {
    return <img src={`https:${image.file.url}`} alt={image.description} className={additionalClass} />;
  }
  return null;
};

export default Image;
