import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import FlexibleLink from '../../ui/FlexibleLink';
import { ILink } from '../../ui/FlexibleLink/type';
import Headline from '../../ui/Headline';
import Image from '../../ui/Image';
import { IImage } from '../../ui/Image/types';
import * as styles from './styles.module.scss';

type FullwidthImageTeaserProps = {
  bgImage: IImage;
  headline: string;
  subHeadline: string;
  link: ILink;
};

const FullwidthImageTeaser: React.FC<FullwidthImageTeaserProps> = ({ bgImage, headline, subHeadline, link }) => {
  return (
    <section className={styles.container}>
      {bgImage && <Image image={bgImage.image} additionalClass={styles.img} />}
      <div className={styles.wrapper}>
        {headline !== null && (
          <Parallax y={[-20, 20]}>
            {subHeadline !== null && <p className={styles.subHeadline}>{subHeadline}</p>}
            <Headline level={2} classLevel={1} color="White" additionalClass={styles.hl}>
              {headline}
            </Headline>
            {link && <FlexibleLink link={link} />}
          </Parallax>
        )}
      </div>
    </section>
  );
};

export default FullwidthImageTeaser;
