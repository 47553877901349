/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import Headline from '../../ui/Headline';
import Accordion from '../../ui/Accordion';
import RichText from '../../ui/RichText';
import { ILink } from '../../ui/FlexibleLink/type';
import FlexibleLink from '../../ui/FlexibleLink';
import { IImage } from '../../ui/Image/types';
import * as styles from './styles.module.scss';
import Image from '../../ui/Image';

type AccordionTeaserProps = {
  headline: string;
  accordionEntries: React.ComponentProps<typeof Accordion>['accordionEntries'];
  accordionType: React.ComponentProps<typeof Accordion>['accordionType'];
  leftImages?: Array<IImage>;
  subHeadline?: string;
  text?: Document;
  link?: ILink;
};

const AccordionTeaser = ({
  headline,
  subHeadline = undefined,
  text = undefined,
  leftImages = undefined,
  link = undefined,
  accordionEntries,
  accordionType,
}: AccordionTeaserProps) => {
  const [accordionIndex, setAccordionIndex] = useState(0);
  return (
    <Container fullWidth additionalClass={styles.container}>
      <Row>
        <Col desktop={6} tablet={6} mobile={12} additionalClass="desktop:pr-80">
          {subHeadline && <p className="uppercase font-algiers">{subHeadline}</p>}
          {!leftImages && (
            <Headline level={2} color="Black">
              {headline}
            </Headline>
          )}
          {text && (
            <div className="mb-50">
              <RichText content={text} additionalClass={styles.copyText} />
            </div>
          )}
          {link && <FlexibleLink link={link} />}
          {leftImages && <Image image={leftImages[accordionIndex].image} additionalClass={styles.img} />}
        </Col>
        <Col desktop={6} tablet={6} mobile={12} additionalClass="mt-50 desktop:mt-0">
          {leftImages && (
            <Headline level={2} classLevel={3} color="Black">
              {headline}
            </Headline>
          )}
          <Accordion
            accordionEntries={accordionEntries}
            accordionType={accordionType}
            changeIndex={setAccordionIndex}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AccordionTeaser;
