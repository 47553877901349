import React from 'react';
import c from 'classnames';
import Icon from '../../Icon';
import * as styles from './style.module.scss';

type Props = {
  onClick?: () => void;
  direction: 'left' | 'right';
};

const Arrow: React.FC<Props> = ({ onClick, direction }) => {
  const isLeft = direction === 'left';
  const directionClass = isLeft ? styles.arrowLeft : styles.arrowRight;

  return (
    <button type="button" className={c(styles.arrow, directionClass)} onClick={onClick}>
      <div className="w-25">
        <Icon name="arrow" />
      </div>
    </button>
  );
};

export default Arrow;
