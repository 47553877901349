import React from 'react';
import c from 'classnames';

type RowProps = {
  nogap?: boolean;
  additionalClass?: string;
};

const Row: React.FC<RowProps> = ({ children, nogap = false, additionalClass }) => (
  <div className={c('grid grid-cols-12 tablet:grid-cols-12', { 'gap-8': !nogap }, additionalClass)}>{children}</div>
);

export default Row;
