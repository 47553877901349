import { Link } from 'gatsby';
import React from 'react';
import c from 'classnames';
import { NavigationItem, SiteSettings } from '../Layout/types';
import * as styles from './styles.module.scss';

type FooterNavigationProps = {
  footerNavigation: SiteSettings['footerNav1'] | SiteSettings['footerNav2'] | SiteSettings['footerNav3'];
};

type NavigationLinkProps = {
  link: NavigationItem;
};

const NavigationLink: React.FC<NavigationLinkProps> = ({ link }) => {
  if (link.url) {
    return (
      <a href={link.url || ''} className={c(styles.link)} target="_blank" rel="noreferrer">
        {link.label}
      </a>
    );
  }

  return (
    <Link to={`/${link.internalLink.slug}`} activeClassName={styles.activeLink} className={c(styles.link)}>
      {link.label}
    </Link>
  );
};

const FooterNavigation: React.FC<FooterNavigationProps> = ({ footerNavigation }) => {
  return (
    <nav className={styles.footerNav}>
      {footerNavigation.map((item: NavigationItem, i: number) => (
        <NavigationLink link={item} key={i} />
      ))}
    </nav>
  );
};

export default FooterNavigation;
