import React, { useRef, useState } from 'react';
import cn from 'classnames';
import Image from '../../ui/Image';
import ContentSlider from '../../ui/ContentSlider';
import { IImage } from '../../ui/Image/types';
import * as styles from './styles.module.scss';

type Tabs = {
  title: string;
  images: Array<IImage>;
};

type ImageSliderContentTeaserProps = {
  tabs: Array<Tabs>;
};

const ImageSlider = ({ tabs }: ImageSliderContentTeaserProps) => {
  const sliderRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    cennter: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const changeTab = (index: number) => {
    setTabIndex(index);
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  return (
    <section className={styles.container}>
      <div className="desktop:relative">
        <div className={styles.sliderNavWrapper}>
          <div className={styles.sliderNav}>
            {tabs.map((item, idx) => (
              <button
                type="button"
                onClick={() => changeTab(idx)}
                key={idx}
                className={cn(styles.btn, { [styles.active]: tabIndex === idx })}
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
        <ContentSlider additionalSettings={settings} ref={sliderRef}>
          {tabs[tabIndex].images.map(({ image }, idx) => (
            <div key={idx}>
              <Image image={image} additionalClass={styles.img} />
            </div>
          ))}
        </ContentSlider>
      </div>
    </section>
  );
};

export default ImageSlider;
