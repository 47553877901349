import React from 'react';
import { SiteSettings } from '../Layout/types';
import MainNav from '../Navigation';
import { container } from './styles.module.scss';

type HeaderProps = {
  siteSettings: SiteSettings;
  isHome: boolean;
};

const Header: React.FC<HeaderProps> = ({ siteSettings, isHome }) => (
  <header className={container}>
    <MainNav siteSettings={siteSettings} isHome={isHome} />
  </header>
);

export default Header;
