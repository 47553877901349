import React from 'react';
import ContentComonents from '../components/cms';
import Layout from '../modules/Layout';

const ContentPage = ({ pageContext, location }) => {
  const isHome = location.pathname === '/';
  return (
    <div style={{ zIndex: 5 }}>
      <Layout pageName={pageContext.title} description={pageContext.seoDescription} isHome={isHome}>
        <ContentComonents components={pageContext.content} />
      </Layout>
    </div>
  );
};

export default ContentPage;
