import { Link } from 'gatsby';
import React from 'react';
import c from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ILink } from './type';
import * as styles from './style.module.scss';

type FlexibleLinkProps = {
  link: ILink;
  additionalClass?: string;
};

const FlexibleLink: React.FC<FlexibleLinkProps> = ({ link, additionalClass = undefined }) => {
  if (link.url) {
    return (
      <a href={link.url || ''} className={c(styles.link, additionalClass)}>
        {link.label}
      </a>
    );
  }

  if (link.anchor) {
    return (
      <AnchorLink
        to={`/${link.internalLink?.slug || ''}${link.anchor ?? `#${link.anchor}`}`}
        className={c(styles.link, additionalClass)}
      >
        {link.label}
      </AnchorLink>
    );
  }

  return (
    <Link to={`/${link.internalLink?.slug}`} className={c(styles.link, additionalClass)}>
      {link.label}
    </Link>
  );
};

export default FlexibleLink;
