import React from 'react';
import Col from '../../ui/Grid/Col';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import * as styles from './styles.module.scss';

type GoogleMapsTeaserProps = {
  title: string;
  url: string;
  headline: string;
  subline: string;
  text: Document;
};

const GoogleMapsTeaser: React.FC<GoogleMapsTeaserProps> = ({ headline, subline, text }) => {
  return (
    <Container additionalClass={styles.container}>
      <Row>
        <Col mobile={12} desktop={6} />
        <Col mobile={12} desktop={6} additionalClass="self-center">
          {subline && <p className="uppercase font-algiers">{subline}</p>}
          <Headline level={2} color="Black">
            {headline}
          </Headline>
          <div className="mb-30">
            <RichText content={text} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleMapsTeaser;
